/************** Global styling for all pages ***********/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

.builder-button, .builder-icon {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.site_content {
  flex: 1;
}

a, a:hover, a:focus {
  transition: 0.5s;
  outline: none;
}

a {
  color: $body-font-color;
  text-decoration: none;
}

button {
  border: none;
}

hr {
  border: none;
  height: 1px;
  background: #ebebeb;
  margin: 18px 0;
}

select {
  background-color: transparent;
}

select:focus-visible {
  outline: none;
}

.container {
  padding: 0 15px;
  margin: auto !important;
  max-width: 1280px;
  width: 100%;
  height: 100%;
}

.d-flex {
  display: flex;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid black;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.truncateF16 {
  max-height: 8rem;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

.cookie_body {
  max-width: 441px !important;
  width: 100% !important;
  background-color: #ffffff !important;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2) !important;
  border-radius: 10px !important;
  bottom: 20px !important;
  left: 20px !important;
  z-index: 9999999999 !important;
  display: none;
}

.cookie_body_elements {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  div {
    > h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-transform: capitalize;
      color: #161616;
      margin-bottom: 5px;
    }

    > p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #333333;
      margin-bottom: 0 !important;
    }
  }
}

.cookie_button_wrapper {
  width: 100% !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.cookie_button {
  color: #ffffff !important;
  background: $main-theme-color-light !important;
  font-size: 16px !important;
  border-radius: 30px !important;
  padding: 10px 20px !important;
  border-style: none !important;

  &:hover {
    background-color: $dark-color !important; // $main-theme-color-hover
    color: $dark-opposite-color !important
  }
}

.cookie_image {
  > svg {
    > path {
      fill: $main-theme-color-light !important;
    }
  }
}

.cookie_content {
  margin: 15px 15px 0 !important;
}

@media (max-width: 481px) {
  .cookie_body {
    left: 0 !important;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: $main-theme-color-light;
}

.fieldset-params-fms {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .contact_field {
    width: calc((100% - 20px) / 3);
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}

@media screen and (max-width: 991px) {
  .fieldset-params-fms {
    margin-bottom: 10px;
    flex-direction: column;

    input {
      padding: 16.5px 14px;
    }
  }
}

.chek-fms {
  justify-content: center;
  align-items: center;
  padding: 6px;
  height: 50px;
  overflow: hidden;

  svg {
    height: 50px;
    min-width: 50px;
    margin-right: 16px;
  }
}

.modal-body {
  span {
    position: relative !important;
  }

  img {
    position: unset !important;
    height: auto !important;
    width: auto !important;
    max-height: unset !important;
    max-width: unset !important;
    min-height: unset !important;
    min-width: unset !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 928px) {
  .products-list__body {
    .products-list__item {
      margin-right: auto;
    }

    .products-list__item:nth-child(3) {
      margin-right: unset;
    }
  }
}

.contact-with-us {
  padding: 1.5rem !important;
}

.contact-customhtml-fms {
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 100px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .contact-customhtml-fms {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .contact-with-us {
    .inner-addToCart {
      width: 100%;
    }
  }
}

.text-danger {
  font-size: 12px;
  color: red;
}

.text-success {
  font-size: 12px;
  color: #2ba847;
}
.error-page-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .error-page-container_section {
    width: 60%;
    text-align: center;

    h3 {
      font-size: 23px !important;
    }

    p {
      span {
        cursor: pointer;
      }
    }
  }
}

///////////// FIXME: in future transfer this part to shared.module.scss or in scss file for product gallery

.carousel-parent {
  .slick-track {
    margin-left: 0 !important;
  }
}

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
}

.faild-toast-fms {
  display: flex;
  justify-content: center;
  align-items: center;
}

.faild-toast-fms,
.chek-fms {
  justify-content: center;
  align-items: center;
  padding: 6px;
  height: 50px;
  overflow: hidden;

  svg {
    height: 50px;
    min-width: 50px;
    margin-right: 16px;
  }
}

.rotT_fms {
  transform: rotate(180deg);
}